@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet');
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap");

body {
  font-family: "Roboto" !important;
}

@font-face {
  font-family: "BestermindRegular";
  src: url("./assets/fonts/Bestermind/BestermindRegular.ttf");
  src: url("./assets/fonts/Bestermind/BestermindRegular.ttf")
      format("embedded-opentype"),
    url("./assets/fonts/Bestermind/BestermindRegular.ttf") format("truetype");
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

/* scrollBar */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #555;
}
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
  transition: 0.3s;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  body {
    overflow-x: hidden;
  }
}
